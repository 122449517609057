.vertical-container {
    height: 100%;
}
.vertical-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.padding-left-20 {
    padding-left: 20px;
    font-weight: 600;
}
.grey-shade {
    --background: #f4f0ec;
}
.white-shade {
    background: #fff;
}
.home-banner {
    background-color: #E9C46A;
}
.banner-text {
    color: #111111;
    font-size: 16px;
    font-weight: 600;
}
.banner-h1 {
    color: #333333;
    font-size: 20px;
    font-weight: 500;
}
.btn-text {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
}
.subject-header {
    margin-top: 55px;
    text-align: center;
    font-size: 20px;
    color: #000;

}
.welcome {
    margin: 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.center-label {
    width: 100%;
    text-align: center;
}

.home-icon {
    font-size: 64px;
    color: #dd823b;
}

.home-item {
    font-size: 18px;
    color: #dd823b;
}
.border-bottom {
    border-bottom: 1px solid var(--ion-color-primary);
}
.less-padding {
    padding-top: 8px;
    padding-bottom: 8px;
}
.preview-text {
    color: var(--ion-color-dark);
    font-size: 18px!important;
}